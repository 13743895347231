@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom scrollbar para layouts generales*/
.custom-scrollbar {
  overflow-y: auto;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 9999px;
  background-color: #c2c8d2;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 9999px;
  background-color: #e9ebf2;
}

/* Custom scrollbar para sucursales pantalla dashboard */
.custom-scrollbar-light {
  overflow-x: auto;
  scroll-behavior: smooth;
}

.custom-scrollbar-light::-webkit-scrollbar {
  width: 12px;
}

.custom-scrollbar-light::-webkit-scrollbar-track {
  border-radius: 9999px;
  background-color: #e0e0e0;
}

.custom-scrollbar-light::-webkit-scrollbar-thumb {
  border-radius: 9999px;
  background-color: #c2c8d2;
}

.custom-autofill input:-webkit-autofill,
.custom-autofill input:-webkit-autofill:hover,
.custom-autofill input:-webkit-autofill:focus,
.custom-autofill input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #222222 inset !important;
  -webkit-text-fill-color: #ffffff !important;
  font-family: "Montserrat";
}

.custom-select.MuiOutlinedInput-root.Mui-focused fieldset,
.custom-select.MuiOutlinedInput-root:focus-within fieldset {
  border: #ef0bb8 solid 2px !important;
}
